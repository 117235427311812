const storeState = {
  isAuthenticated: undefined,
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  userpicUrl: null,
  company: null,
  phone: null,
  timezone: null,
  ipCountry: undefined, // undefined = hasn't been set yet, null - hasn't managed to get
  role: undefined,
  isDataLoaded: false,
};

const mutations = {
  SET_USERNAME(state, username) {
    state.username = username;
  },
  SET_FIRST_NAME(state, firstName) {
    state.firstName = firstName;
  },
  SET_LAST_NAME(state, lastName) {
    state.lastName = lastName;
  },
  SET_EMAIL(state, email) {
    state.email = email;
  },
  SET_USER_IS_AUTHENTICATED(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  SET_COMPANY(state, company) {
    state.company = company;
  },
  SET_INDUSTRY(state, industry) {
    state.industry = industry;
  },
  SET_PHONE(state, phone) {
    state.phone = phone;
  },
  SET_TIMEZONE(state, timezone) {
    state.timezone = timezone;
  },
  SET_USER_IP_COUNTRY(state, country) {
    state.ipCountry = country;
  },
  SET_ROLE(state, role) {
    state.role = role;
  },
  SET_IS_DATA_LOADED(state, isDataLoaded) {
    state.isDataLoaded = isDataLoaded;
  },
};

const actions = {
  setUserData({ commit }, userData) {
    commit('SET_USERNAME', userData.username);
    commit('SET_FIRST_NAME', userData.first_name);
    commit('SET_LAST_NAME', userData.last_name);
    commit('SET_USER_IS_AUTHENTICATED', !!userData);
    commit('SET_COMPANY', userData.company);
    commit('SET_INDUSTRY', userData.industry);
    commit('SET_ROLE', userData.role);
    commit('SET_PHONE', userData.phone);
    commit('SET_PHONE', userData.phone);
    commit('SET_IS_DATA_LOADED', true);
    // commit('SET_TIMEZONE', userData.timezone);
  },
  setUserIsNotAuthenticated({ commit }) {
    commit('SET_USER_IS_AUTHENTICATED', false);
    commit('SET_IS_DATA_LOADED', true);
  },
};

const user = {
  namespaced: true,
  state: storeState,
  mutations,
  actions,
};

export default user;

import userApi from '@/api/userApi';

const loadUserDataToStore = async (store) => {
  try {
    const user = await userApi.getUser();
    store.dispatch('user/setUserData', user);
  } catch (e) {
    store.dispatch('user/setUserIsNotAuthenticated');
    if (e.errors[0].status !== 403 && e.errors[0].status !== 401) throw e;
  }
};

export { loadUserDataToStore };

<template>
  <form
    class="ng-vuelidatable-form"
    v-bind="$attrs"
    v-on="listenersWithoutInput"
  >
    <slot name="fieldset">
      <ng-fieldset :fields="fields" v-model="formModel" />
    </slot>
    <slot name="buttons" v-if="showButtons">
      <ng-button v-bind="btnAttrs" @click="submit" :loading="loading">
        {{ btnText }}
      </ng-button>
    </slot>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import vuelidatable from '../../mixins/vuelidatable';
import {
  subscribeToDataError,
  unsubscribeToDataError,
  getErrorDescription,
} from '../../services/errorService';

export default {
  name: 'NgFormVuelidatable',
  props: {
    fields: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    btnAttrs: {
      type: Object,
      default: () => ({
        color: 'primary',
        class: 'mt-4',
      }),
    },
    btnText: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    useCase: {
      type: String,
    },
  },
  mixins: [validationMixin, vuelidatable],
  data() {
    return {};
  },
  validations() {
    return {
      formModel: this.validationRules,
    };
  },
  computed: {
    formModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    formFields() {
      return this.fields;
    },
    errorSubscriptionSettings() {
      return this.useCase ? { useCase: this.useCase } : {};
    },
    listenersWithoutInput() {
      // eslint-disable-next-line no-unused-vars
      const { input, ...otherListeners } = this.$listeners || {};
      return otherListeners;
    },
  },
  watch: {
    vuelidateErrors: {
      deep: true,
      handler() {
        this.setErrorsTo(this.formFields, { validationRoot: 'formModel' });
      },
    },
  },
  created() {
    Object.keys(this.formFields).forEach((fieldName) =>
      // errors should be fired in parent component
      subscribeToDataError(
        { dataKey: fieldName, ...this.errorSubscriptionSettings },
        this.setServerError,
      ),
    );
  },
  beforeDestroy() {
    Object.keys(this.formFields).forEach((fieldName) =>
      unsubscribeToDataError(
        { dataKey: fieldName, ...this.errorSubscriptionSettings },
        this.setServerError,
      ),
    );
  },
  methods: {
    clearServerErrors() {
      Object.keys(this.formFields).forEach((fieldName) => {
        this.$set(this.formFields[fieldName], 'serverErrors', '');
      });
    },
    setServerError(serverError) {
      const fieldName = serverError.source.pointer;
      if (Object.prototype.hasOwnProperty.call(this.formFields, fieldName)) {
        this.$set(this.formFields[fieldName], 'serverErrors', [
          ...this.formFields[fieldName].serverErrors,
          getErrorDescription(serverError),
        ]);
      }
    },
    validate() {
      this.$v.$touch();
      this.clearServerErrors();
      return !this.$v.$invalid;
    },
    resetValidation() {
      this.$v.$reset();
      this.clearServerErrors();
    },
    submit() {
      const isValid = this.validate();
      if (isValid) {
        this.$emit('submit');
      } else {
        this.$emit('validationFailed');
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {
  VApp,
  VRow,
  VCol,
  VIcon,
  VAutocomplete,
  VContainer,
  VMain,
  VSpacer,
  VMenu,
  VBtn,
  VBtnToggle,
  VLayout,
  VNavigationDrawer,
  VDivider,
  VSheet,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VTextField,
  VSelect,
  VDialog,
  VAlert,
  VProgressCircular,
  VDataTable,
  VChip,
  VHover,
  VBreadcrumbs,
  VList,
  VListItem,
  VListItemTitle,
  VListItemIcon,
  VListItemGroup,
  VCheckbox,
  VRadio,
  VRadioGroup,
  VItem,
  VItemGroup,
  VTooltip,
  VExpandTransition,
  VBadge,
  VStepper,
  VStepperHeader,
  VStepperStep,
} from 'vuetify/lib';

Vue.use(Vuetify, {
  components: {
    VApp,
    VRow,
    VCol,
    VIcon,
    VAutocomplete,
    VContainer,
    VMain,
    VSpacer,
    VMenu,
    VBtn,
    VBtnToggle,
    VLayout,
    VNavigationDrawer,
    VDivider,
    VSheet,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VTextField,
    VSelect,
    VDialog,
    VAlert,
    VProgressCircular,
    VDataTable,
    VChip,
    VHover,
    VBreadcrumbs,
    VList,
    VListItem,
    VListItemTitle,
    VListItemIcon,
    VListItemGroup,
    VCheckbox,
    VRadio,
    VRadioGroup,
    VItem,
    VItemGroup,
    VTooltip,
    VExpandTransition,
    VBadge,
    VStepper,
    VStepperHeader,
    VStepperStep,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0070c5',
        accent: '#00b77e',
        secondary: '#e5eef7',
        info: '#2196f3',
        warning: '#ff9800',
        error: '#ff5252',
        success: '#4caf50',
        'success-dark': '#497D6D',
        grey: '#B3B9BE',
        'grey-dark': '#808A93',
        'grey-bg': '#F2F4F5',
      },
    },
    options: {
      customProperties: true,
    },
  },
  breakpoint: {
    mobileBreakpoint: 'sm',
  },
  // icons: {
  //   iconfont: 'mdi'
  // }
});

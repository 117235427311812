import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import * as userService from '@/services/userService';

const AuthIndex = () => import('@/pages/auth/AuthIndex.vue');
const LoginEmail = () => import('@/pages/auth/LoginEmail.vue');
const LoginPassword = () => import('@/pages/auth/LoginPassword.vue');
const EmailVerification = () => import('@/pages/auth/EmailVerification.vue');
const SignupDetails = () => import('@/pages/auth/SignupDetails.vue');
const SignupEmail = () => import('@/pages/auth/SignupEmail.vue');
const Team = () => import('@/pages/Team.vue');
const MemberOfTeams = () => import('@/components/team/MemberOfTeams.vue');
const OwnTeam = () => import('@/components/team/OwnTeam.vue');
const ErrorPage = () => import('@ngservices_front/pages/ErrorPage.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: 'login/1',
  },
  {
    path: '/login',
    component: AuthIndex,
    redirect: 'login/1',
    meta: {
      isAuthRequired: false,
    },
    children: [
      {
        path: '1',
        component: LoginEmail,
        meta: {
          isAuthRequired: false,
        },
      },
      {
        path: '2',
        component: LoginPassword,
        meta: {
          isAuthRequired: false,
        },
      },
    ],
  },
  {
    path: '/signup',
    component: AuthIndex,
    redirect: 'signup/0',
    meta: {
      isAuthRequired: false,
    },
    children: [
      {
        path: '0',
        component: SignupEmail,
        meta: {
          isAuthRequired: false,
        },
      },
      {
        path: 'email-verification',
        component: EmailVerification,
        meta: {
          isAuthRequired: false,
        },
      },
      {
        path: 'details',
        component: SignupDetails,
        redirect: '/signup/details/1',
        props: true,
        meta: {
          isAuthRequired: false,
        },
        children: [
          {
            path: ':step(1|2)',
            component: SignupDetails,
            props: true,
            meta: {
              isAuthRequired: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/teammanage',
    name: 'teammanage',
    component: Team,
    redirect: '/teammanage/owner',
    meta: {
      isAuthRequired: true,
    },
    children: [
      {
        path: 'owner',
        name: 'ownTeam',
        component: OwnTeam,
        meta: {
          isAuthRequired: true,
        },
      },
      {
        path: 'member',
        name: 'memberOf',
        component: MemberOfTeams,
        meta: {
          isAuthRequired: true,
        },
      },
    ],
  },
  {
    path: '*',
    name: '404',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     setTimeout(() => tryScrollToAnchor(to.hash, 1000, 0));
  //   }
  //   return savedPosition || { x: 0, y: 0 };
  // },
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.user?.isDataLoaded) {
    await userService.loadUserDataToStore(store);
  }

  if (to.meta.isAuthRequired && !store.state.user?.isAuthenticated) return next('/login/1');

  return next();
});

export default router;

<template>
  <ng-button v-bind="$attrs" v-on="$listeners" class="google-signin-button" lightweight>
    <img
      class="google-signin-button__icon"
      src="../../assets/img/google-icon.svg"
      height="24px"
      width="auto"
    />
    {{ $t('app.googleSignin') }}
  </ng-button>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.google-signin-button,
.google-signin-button:hover {
  border-color: rgba(0, 0, 0, 0.24);
}

.google-signin-button__icon {
  margin-left: -10px;
  margin-right: 10px;
}
</style>

import axios from 'axios';
import getCookie from '@/utils/getCookie';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFTOKEN': getCookie('ngid_csrftoken'),
  },
});

const isObject = (x) => x instanceof Object && x !== null;

const buildErrorDescription = (data) => {
  return data.detail || data.description || '';
};

const responseErrorInterceptor = (error) => {
  const { data, status, statusText, config } = error.response;
  const { useCase, url } = config;
  const defaultCode = data.errors ? 'bad_request' : 'system';
  const errorDescription = isObject(data) ? buildErrorDescription(data) : statusText;
  const errors = data.errors
    ? data.errors.map((e) => ({ ...e, useCase, status, url, code: defaultCode }))
    : [
        {
          code: data?.code || defaultCode,
          status,
          url,
          useCase,
          source: {
            pointer: '',
          },
          detail: errorDescription,
        },
      ];

  const errorFormatted = {
    errors,
  };
  return Promise.reject(errorFormatted);
};

httpClient.interceptors.response.use((response) => response, responseErrorInterceptor);

export { responseErrorInterceptor };

export default httpClient;
